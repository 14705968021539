import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelectedConsumer } from '../App';

function startLoadingSpinner(elementId) {
  console.debug(`Starting loading spinner for DIV#${elementId}`)
  // Check if the spinner already exists, and if so, do nothing
  if (document.getElementById('spinner-' + elementId)) return;

  var spinner = document.createElement("div");
  spinner.className = "spinner";
  spinner.innerHTML = '<div class="rect1"></div><div class="rect2"></div><div class="rect3"></div><div class="rect4"></div><div class="rect5"></div>';
  spinner.id = 'spinner-' + elementId;  // Assign a unique id to the spinner

  // Append the spinner to the specified div
  document.getElementById(elementId).appendChild(spinner);
}

function stopLoadingSpinner(elementId) {
  console.debug(`Stopping loading spinner for DIV#${elementId}`)
  var spinner = document.getElementById('spinner-' + elementId);
  
  // If the spinner exists, remove it
  if (spinner) {
      spinner.parentNode.removeChild(spinner);
  }
}

function formatDate(dateString) {
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  return new Date(dateString).toLocaleDateString(undefined, options);
}

function ReduceEnergy({ onConsumerChange }) {
  const { selectedConsumer } = useSelectedConsumer();
  const [billingData, setBillingData] = useState('');

  useEffect(() => {
    if (selectedConsumer) {
      startLoadingSpinner('billingBlock');
      axios.get(`${process.env.REACT_APP_API_URL}consumers/${selectedConsumer}/bills`)
        .then(response => {
          //Sort txns by date
          response.data.transactions.sort((a, b) => {
            let dateA = new Date(a.executionDateTime);
            let dateB = new Date(b.executionDateTime);
            
            //return dateA - dateB;  // For ascending order
            return dateB - dateA; // Uncomment this for descending order
          });
          setBillingData(response.data);
        })
        .catch(error => {
          console.error('Error fetching billing data:', error);
        })
        .finally(() => {
          stopLoadingSpinner('billingBlock');
        });
    }
  }, [selectedConsumer]);

  return (
    <div className="contentBody" id='billingBlock'>
      <h2>Reduce Energy - {selectedConsumer}</h2>

      <h3>If you're like us and value green, renewable energy over simply the cheapest energy available, then you've come to the right place. The following tailored tips will see you improve your green credentials:</h3>
      <ul>
        <li>You are currently with ACME Coal Power Pty Ltd. They have a decorated history of digging up the earth for profit. They have a 1⭐ environmental rating as a company, so even by using their 'Green Power' plan, you are still giving your money to a company that does not share your values. We have found you a plan that does share your values and still remains good value. We reccomend you switch to ACME Clean Power Pty Ltd, which has a 5⭐ environmental rating and guarantees 100% renewable energy sources. Their 'Every Day Solar' plan will cost you around $100 more per year than your current plan, but that is a small price to pay to do your part for a better future. <a href="">Switch now</a> <a href="">More information</a></li>
        <li>Your current retailer has a good environmental rating (4⭐), but you are not enrolled in their green power option. Enrolling will increase your bill by around $100 per year. Even with this additional cost, your current plan is considered good value. <a href="">Contact ACME Pty Ltd and ask to enroll in their green power program now.</a></li>
        <li>Your solar power system is not as big as it could be. The Essential Energy network that you are connected to allows residential inverters of up to 10kW. Your inverter has a capacity of 5 kW. Based on your usage profile, an additional 5 kW output would deliver XXXkWh of renewable energy to the grid per year, saving YYYtons of CO2 from entering the atmosphere. We have a 5⭐ solar installer in your postcode that is able to provide a 4.5⭐ 10kW system for around $10,000*. <a href=''>Request a call</a> <a href=''>More details</a></li>
        <li>You don't have solar. For your location and usage patterns, we reccomend this 5⭐ 5kW solar power system. Once installed, a change to the ACME clean energy plan (5⭐) will mean you are running on 100% renewable energy and contributing around 2000 kWh of renewable energy back into the grid, saving 1000 tons of CO2 emissions. We have a 5⭐ solar installer who can reach out for a formal quote, if you would like. <a href="">Request a call</a> <a href="">More details</a></li>
        <li>You're renting or you're in a unit so can't install solar panels? No problem. You are able to enrol in a Solar Garden program where you lease a portion of a commercial solar farm for a number of years and get the feed-in tariffs generated by that portion credited to your bill as if those panels were on your roof! Have solar without the need for panels! We recommend the ACME Solar Garden program which would require an upfront payment of around $4,500 and a switch to the ACME retailer (4⭐ environmental rating) Solar Garden plan (4⭐). Accounting for the change in plan, the solar feed-in tariff credits and your household usage patterns, you will add around 1000 kWh of renewable electricity to the grid (-500 tons CO2) and save around $500 per year from your bill</li>
        <li>Are you still using gas? Stove tops, water heaters, ovens and space heaters that run on gas are prime targets for replacement if you want to have a positive impact on the environment. When you are able to, it is best to replace these with energy efficient electric options.</li>
        <li>All the hints and tips that can reduce your power bill will also reduce your power consumption:</li>

        <h3>For everyone:</h3>
      <ul>
        <li>Use LED lights - you're not still using halogen, compact fluro or even incandesent lights still are you??? 🫠 With the prices of LED lights (even the 'smart' ones) and the ease of installation, there really is no excuse these days. Come on...</li>
        <li>Turn down the A/C - we all want to be comfortable, but that comes at a cost. Heating uses more energy than cooling, so put on an extra layer in winter before you turn on the reverse cycle A/C. Summer in most part of Aus is difficult, but we could all probably ease back on the A/C by a degree or two.</li>
        <li>Review your fridge - fridges are always on, 24x7. So they can be a culprit. How much energy does yours use??? Check the label or look it up online. If it uses more than X kWh per L of volume, then consider an investment to get a new, energy efficient one.</li>
        <li>Review your electric hot water system - this is another appliance that is energy hungry and is used every single day. Check the thermostat and dial it down a ntoch or two. Is the system way too big for the size of the house? Is it old? If you can, replace your electric tank with a heat-pump hot water system. They will save you on average X% compared to your electric. You are in a postcode that is currently eligible for government rebates, making the payback period Y years for a house your size. </li>
        <li>What other appliances do you run all day? Some common culprits are computers, gaming consoles, stereos, televisions, etc... If they don't need to be on, turn them off when you're not using them. You might be suprised with how much this can save you.</li>
        <li>Pool pumps, slab heating (lucky you!!!) - do they need to be running as long as they do? These guys will cause havoc with your bills, so consider reducing how long they are running for and check to make sure they run during the cheapest electricity period (if you are on a time-of-use tariff structure).</li>
      </ul>

      <h3>For those with solar</h3>
      <ul>
        <li>
          By far the biggest money gains available from solar is from the savings, not from the feed-in tariff revenue. These gains are only amplified as energy prices go up and feed-in tariff prices go down. So here are a list of things that you should be doing between the hours of 10am and 3pm to maximise those savings:
          <ul>
            <li>Washing - use your machine's timer to try and have the machine run during bright daylight</li>
            <li>Dishwashing - use your machine's timer to try and run the dishwasher during sunny hours</li>
            <li>Hot water - have a sparky install a timer for your electric hot water system so that it heats up during the middle of the day rather than overnight</li>
            <li>Dryer - the best solar dryer is a clothesline, but next to that try to run your tumble dryer during the day when the sun is shining bright</li>
            <li>Pool pump - set the timer to run during the daylight hours. If it needs longer, then make use if your plan's off-peak periods (for those on TOU plan).</li>
            <li>Electric Vehicle - if you're at home during the day and you have an EV, make sure it is plugged in during the daylight hours.</li>
            <li>Water pumps - if you have a garden fountain or you have a water tank with a pressure pump, try to find ways for these to operate mainly during daylight hours only.</li>
            <li>Etc...</li>
          </ul>
          Any excess solar energy will be sold to the grid, and this is where having the right plan will help maximise the savings available. After a few months of your new day-time behaviour, we will check to see if there is a plan better suited to your new patterns and let you know what we find.
        </li>
        <li>With that in mind, the way to use as much of your own solar as possible is to store it in a battery 🔋. Then you can use your energy any time of the day. The downside is that they are not cheap. However, with the right setup and some behaviour changes, you can increase your cashflow position by around $X per week, making for a Y year payback period. Our solar + battery simulator reccomends the following 4.5 ⭐ system and a change to the ACME Battery plan which will result in a payback period of Z years for you, well within the equipment's warrenty period of Y years. See our <a href=''>solar & battery simulation tool</a> for more details.</li>
      </ul>

      <h3>For those with solar and a battery</h3>
      <ul>
        <li>Consider a wholesale market plan, such as Amber Electric</li>
        <li>Consider participating in a Virtual Power Plant (VPP)</li>
      </ul>

      </ul>
    </div>
  );
}

function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export default ReduceEnergy;
