import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelectedConsumer } from '../App';

function startLoadingSpinner(elementId) {
  console.debug(`Starting loading spinner for DIV#${elementId}`)
  // Check if the spinner already exists, and if so, do nothing
  if (document.getElementById('spinner-' + elementId)) return;

  var spinner = document.createElement("div");
  spinner.className = "spinner";
  spinner.innerHTML = '<div class="rect1"></div><div class="rect2"></div><div class="rect3"></div><div class="rect4"></div><div class="rect5"></div>';
  spinner.id = 'spinner-' + elementId;  // Assign a unique id to the spinner

  // Append the spinner to the specified div
  document.getElementById(elementId).appendChild(spinner);
}

function stopLoadingSpinner(elementId) {
  console.debug(`Stopping loading spinner for DIV#${elementId}`)
  var spinner = document.getElementById('spinner-' + elementId);
  
  // If the spinner exists, remove it
  if (spinner) {
      spinner.parentNode.removeChild(spinner);
  }
}

function formatDate(dateString) {
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  return new Date(dateString).toLocaleDateString(undefined, options);
}

function Solar({ onConsumerChange }) {
  const { selectedConsumer } = useSelectedConsumer();
  const [billingData, setBillingData] = useState('');

  useEffect(() => {
    if (selectedConsumer) {
      startLoadingSpinner('billingBlock');
      axios.get(`${process.env.REACT_APP_API_URL}consumers/${selectedConsumer}/bills`)
        .then(response => {
          //Sort txns by date
          response.data.transactions.sort((a, b) => {
            let dateA = new Date(a.executionDateTime);
            let dateB = new Date(b.executionDateTime);
            
            //return dateA - dateB;  // For ascending order
            return dateB - dateA; // Uncomment this for descending order
          });
          setBillingData(response.data);
        })
        .catch(error => {
          console.error('Error fetching billing data:', error);
        })
        .finally(() => {
          stopLoadingSpinner('billingBlock');
        });
    }
  }, [selectedConsumer]);

  return (
    <div className="contentBody" id='billingBlock'>
      <h2>Solar - {selectedConsumer}</h2>

      <h3>You have solar - here are some details about your solar</h3>
      <ul>
        <li>Inverter capacity: X kW</li>
        <li>Solar array size: Y kW</li>
        <li>Avg exports: Z kWh per year, z kWh per day</li>
      </ul>

      <h4>Integrate your inverter to monitor your solar array. We'll keep an eye on it each day and let you know if we see any issues.</h4>
      <button>Integrate Inverter</button>
      <hr />
      <h3>You don't have solar - here are your options</h3>
      <ul>
        <li>Owner/occupier of freestanding house: A y kW array with a X kW inverter would provide approximately Z kWh of energy per day. Against your usage profile on the ACME Saver plan, this would result in annual savings of $XY, meaning a payback period of N years.</li>
        <li>Owner/occupier of unit: TBA (solar garden arrangements)</li>
        <li>Renter: TBA (solar garden arrangements)</li>
      </ul>
    </div>
  );
}

function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export default Solar;
