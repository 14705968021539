import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Chart, registerables } from 'chart.js';
import { useSelectedConsumer } from '../App';

Chart.register(...registerables);

function startLoadingSpinner(elementId) {
    console.debug(`Starting loading spinner for DIV#${elementId}`)
    // Check if the spinner already exists, and if so, do nothing
    if (document.getElementById('spinner-' + elementId)) return;

    var spinner = document.createElement("div");
    spinner.className = "spinner";
    spinner.innerHTML = '<div class="rect1"></div><div class="rect2"></div><div class="rect3"></div><div class="rect4"></div><div class="rect5"></div>';
    spinner.id = 'spinner-' + elementId;  // Assign a unique id to the spinner

    // Append the spinner to the specified div
    document.getElementById(elementId).appendChild(spinner);
}

function stopLoadingSpinner(elementId) {
    console.debug(`Stopping loading spinner for DIV#${elementId}`)
    var spinner = document.getElementById('spinner-' + elementId);
    
    // If the spinner exists, remove it
    if (spinner) {
        spinner.parentNode.removeChild(spinner);
    }
}

function daysBetween(date1Str, date2Str) {
    // Convert the date strings to Date objects
    const date1 = new Date(date1Str);
    const date2 = new Date(date2Str);
  
    // Calculate the difference in milliseconds
    const diffInMs = Math.abs(date2 - date1);
  
    // Convert milliseconds to days (1 day = 24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
    const days = diffInMs / (24 * 60 * 60 * 1000);
  
    return Math.round(days);
  }


function PlanComparison() {
  const { selectedConsumer, selectedServicePoint, selectedConsumerAccount } = useSelectedConsumer();
  const planComparisonChartRef = useRef(null);
  const [consumers, setConsumers] = useState([]);
  //const [selectedConsumer, setSelectedConsumer] = useState('');
  const [originalPlanCostData, setOriginalPlanCostData] = useState([]);
  const [filteredPlanCostData, setFilteredPlanCostData] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedTariff, setSelectedTariff] = useState('');
  const [planComparisonChart, setPlanComparisonChart] = useState(null);
  const [brands, setBrands] = useState([]);
  const [tariffs, setTariffs] = useState([]);
  const [greenPower, setGreenPower] = useState(0);
  const [refPeriod, setRefPeriod] = useState({});
  const [refreshButton, setRefreshButton] = useState(false);
  const [consumerTariff, setConsumerTariff] = useState('');
  const [existingPlanCosts, setExistingPlanCosts] = useState({});
  const [currentPlanDetails, setCurrentPlanDetails] = useState({});
  const [postcode, setPostcode] = useState('');

  var runCount = 0;

  useEffect(() => {
    runCount++;

    // Initialize chart with empty data
    if (runCount == 1) {
        initialisePlanComparisonChart([]);
        startLoadingSpinner('navBar');

      axios.get(process.env.REACT_APP_API_URL + 'consumers')
        .then(response => {
            setConsumers(response.data);
        })
        .catch(error => {
            console.error('Error fetching consumer IDs:', error);
        }).finally(() => {
            stopLoadingSpinner('navBar');
        });
    }
  }, []);

  useEffect(() => {
    setRefreshButton(false);

    if (selectedConsumer && selectedServicePoint && selectedServicePoint != '') {
        console.debug(`Selected consumer changed to ${selectedConsumer}. Requested their data.`)
        startLoadingSpinner('comparisonChartBox');

      axios.get(`${process.env.REACT_APP_API_URL}consumers/${selectedConsumer}/accounts/${selectedConsumerAccount}/service-points/${selectedServicePoint}/plan-costs?annualised=true&periodStartDate=${refPeriod.startDate || ''}&periodEndDate=${refPeriod.endDate || ''}`) //&topn=10`)
        .then(response => {
          console.debug(`Received cost data for consumer ${selectedConsumer}`);
          const data = response.data;
          setOriginalPlanCostData(data);
          setFilteredPlanCostData(data);
        })
        .catch(error => {
          console.error(`Error fetching cost data for consumer '${selectedConsumer}':`, error);
          initialisePlanComparisonChart([]);
        }).finally(() => {
            stopLoadingSpinner('comparisonChartBox');
      });

      axios.get(`${process.env.REACT_APP_API_URL}consumers/${selectedConsumer}/accounts/${selectedConsumerAccount}/service-points/${selectedServicePoint}/plan`)
        .then(response => {
            console.debug(`Received plan details for consumer ${selectedConsumer}`);
            const data = response.data;
            setCurrentPlanDetails(data);
        })
        .catch(error => {
            console.error(`Error fetching plan details for consumer '${selectedConsumer}':`, error);
        }).finally(() => {
        });


    axios.get(`${process.env.REACT_APP_API_URL}consumers/${selectedConsumer}/accounts/${selectedConsumerAccount}/service-points/${selectedServicePoint}/postcode`)
        .then(response => {
            console.debug(`Received postcode for consumer ${selectedConsumer}`);
            const data = response.data;
            setPostcode(data);
        })
        .catch(error => {
            console.error(`Error fetching postcode for consumer '${selectedConsumer}':`, error);
        }).finally(() => {
        });
    }

}, [selectedServicePoint, refreshButton]);

  useEffect(() => {
    if (originalPlanCostData.length > 0) {
        //const existingPlanCosts = originalPlanCostData.find(item => item?.planId == 'current_plan');
        //Populate filter values for this new data
        setBrands([...new Set(originalPlanCostData.map(item => { return JSON.stringify({"brand": item.brand, "brandName": item.brandName})}))].map(item => JSON.parse(item)).sort((a, b) => a.brandName.localeCompare(b.brandName)));
        setTariffs([...new Set(originalPlanCostData.map(item => { return JSON.stringify({"tariffKey": item.pricingModel, "tariffLabel": item.pricingModel})}))].map(item => JSON.parse(item)));
    }

    //Reset filters
    setGreenPower(0);
    setSelectedBrand(undefined);
    setSelectedTariff(undefined)

    //Update the cost gauge
    //if (originalPlanCostData.length > 0) updateCostGauge(costGauge, {needleValue: existingPlanCosts.consumerCosts.total})

  }, [originalPlanCostData]);

  useEffect(() => {
    let consumerTariff = currentPlanDetails.planDetail ? currentPlanDetails.planDetail.electricityContract.pricingModel : 'unknown';
    setConsumerTariff(consumerTariff);
    setSelectedTariff(consumerTariff);
  }, [currentPlanDetails]);

  useEffect(() => {
    console.debug(`A filter changed`, {
        "brand": selectedBrand,
        "tariff": selectedTariff, 
        "greenPower": greenPower
    });

    var filteredData = originalPlanCostData;
    if (originalPlanCostData.length > 1) {
        if (selectedBrand) filteredData = filteredData.filter(item => item.brand === selectedBrand);
        if (selectedTariff) filteredData = filteredData.filter(item => item.pricingModel == selectedTariff);
        if (greenPower) {
            filteredData = filteredData.filter(item => {
                item.consumerCosts.breakdown.greenPower = 0;
                let greenPowerFilterPercent = (Number(greenPower)/100);

                //If greenPower filter is set to 0 then return true for all plans
                if (greenPowerFilterPercent == 0) return true;

                //If there is intrinsic greenPower available for this plan to meet the filtered amount, then return true
                if (item.greenPower && item.greenPower?.intrinsic >= greenPowerFilterPercent) return true;

                //If there is optional greenPower available for this plan to meet the filteres amount, then return true
                if (item.greenPower && item.greenPower.optional) {
                    //Find optional tiers that satisfy the filter
                    let optionalTiers = item.greenPower.optional.filter(optionalTier => optionalTier.max >= greenPowerFilterPercent);
                    //Find the smallest of these matching tiers (so we can apply pricing)
                    let smallestOptionalTier = optionalTiers.reduce((acc, curr) => {
                        return curr.max < acc.max ? curr : acc;
                    }, optionalTiers[0]);

                    //Calculate and add the greenpower costs
                    if (smallestOptionalTier.type == 'perKWh') {
                        let greenPowerOptionalCostGeneral = (smallestOptionalTier.amount * item.consumerCosts.referenceUsage.generalImport);
                        let greenPowerOptionalCostControlledLoad =  (smallestOptionalTier.amount * (item.consumerCosts.referenceUsage.clImport || 0))
                        item.consumerCosts.breakdown.greenPower = greenPowerOptionalCostGeneral + greenPowerOptionalCostControlledLoad;
                    } else if (smallestOptionalTier.type == 'perDay') {
                        let days = daysBetween(item.consumerCosts.periodStart, item.consumerCosts.periodEnd);
                        item.consumerCosts.breakdown.greenPower = smallestOptionalTier.amount * days;
                    }

                    return true;
                }
            });
        }
        if (!filteredData.find(plan => plan?.planId == "current_plan")) {
            const currentPlanCosts = originalPlanCostData.find(item => item?.planId == 'current_plan');
            if (currentPlanCosts) filteredData.push(currentPlanCosts);
        }
        if (!filteredData.find(plan => plan?.planId == "actual_costs")) {
            const previousCosts = originalPlanCostData.find(item => item?.planId == 'actual_costs');
            if (previousCosts) filteredData.push(previousCosts);
        }
    }
    setFilteredPlanCostData(filteredData);
  }, [selectedBrand, selectedTariff, greenPower]);

  useEffect(() => {
    console.debug(`Filtered data changed (${filteredPlanCostData.length} items)`)
    // Update chart with filtered data
    if (planComparisonChart) {
        updatePlanComparisonChart(planComparisonChart, filteredPlanCostData);
      }
  }, [filteredPlanCostData]);

  const initialisePlanComparisonChart = (data) => {
    console.debug(`Initialising plan comparison chart...`);
    // Your chart initialisation code here
    // Use chartRef.current instead of 'myChart'
    // Destroy the existing chart if it exists
    if (planComparisonChart) {
        planComparisonChart.destroy();
    }

    let datasets = [];
    let labels = [];

    const highlightPlugin = {
        afterDraw: function(chart, easing) {
            const dataset = chart.data.datasets[0];  // get your dataset
            const meta = chart.getDatasetMeta(0); // get metadata for the first dataset
            const ctx = chart.ctx; // chart context for drawing

            if (dataset) {
                dataset.data.forEach(function(value, index) {
                    if (dataset.planIds[index] === 'current_plan' || dataset.planIds[index] === 'actual_costs') {  // check if this is the bar you want to highlight
                        const bar = meta.data[index];  // get the bar you want to highlight

                        ctx.save();  // save the context state
                        ctx.fillStyle = '#FF0000';  // set highlight color
                        ctx.lineWidth = 1;  // set line width
                        ctx.fillRect(bar.x - bar.width / 2, bar.y, bar.width, bar.height); // draw a rectangle on the bar
                        ctx.restore();  // restore the context state
                    }
                });
            }
        }
    };

    const newChart = new Chart(planComparisonChartRef.current, {
        type: 'bar',
        data: { labels, datasets },
        options: {
            responsive: true,
            scales: {
                x: { stacked: true },
                y: {
                    stacked: true,
                    ticks: {
                        // Include a dollar sign in the ticks
                        callback: function(value, index, values) {
                            return '$' + value.toLocaleString();
                        }
                    }
                }
            },
            plugins: {
                title: {
                    display: true,
                    text: 'Plan Comparison'
                }
            }
        },
        plugins: [highlightPlugin]
  });
    setPlanComparisonChart(newChart);

    // Cleanup function
    return () => {
        newChart.destroy();
    };
  };

  const updatePlanComparisonChart = (targetChart, data) => {
    console.debug(`updatePlanComparisonChart called`)
    // Your chart update code here
    if (data.length == 0) {
        targetChart.data.datasets = [];
        targetChart.data.labels = [];
        targetChart.update();
        return;
    }

    // Use chart.data instead of 'myChart.data'
    data.sort((a, b) => {
        let sumA = a.consumerCosts.breakdown.usage + a.consumerCosts.breakdown.export + a.consumerCosts.breakdown.supply + a.consumerCosts.breakdown.clUsage + a.consumerCosts.breakdown.clSupply + a.consumerCosts.breakdown.regularFees + a.consumerCosts.breakdown.otherCosts + (a.consumerCosts.breakdown.greenPower || 0);
        let sumB = b.consumerCosts.breakdown.usage + b.consumerCosts.breakdown.export + b.consumerCosts.breakdown.supply + b.consumerCosts.breakdown.clUsage + b.consumerCosts.breakdown.clSupply + b.consumerCosts.breakdown.regularFees + b.consumerCosts.breakdown.otherCosts + (b.consumerCosts.breakdown.greenPower || 0);
        
        return sumA - sumB; // For ascending order
    });

    //Add aggreate/net cost property to each plan
    data.forEach(item => item.netConsumerCost = (item.consumerCosts.breakdown.usage + item.consumerCosts.breakdown.export + item.consumerCosts.breakdown.supply + item.consumerCosts.breakdown.clUsage + item.consumerCosts.breakdown.clSupply + 
        item.consumerCosts.breakdown.regularFees + item.consumerCosts.breakdown.otherCosts + (item.consumerCosts.breakdown.greenPower || 0)) / 100);

    let referenceCost = data.find(item => item.planId == 'current_plan')?.netConsumerCost || 0;

    //const labels = data.map(item => `${item.brandName}`);
    const labels = data.map(item => `${item.brandName} - ${item.displayName} - ${item.planId} (${item.pricingModel}), $${(item.netConsumerCost - referenceCost).toFixed(2)} (${(((item.netConsumerCost - referenceCost) / referenceCost) * 100).toFixed(2)}%)`);
    const datasets = [
        {
            label: 'Import',
            data: data.map(item => item.consumerCosts.breakdown.usage / 100),
            backgroundColor: 'rgba(255, 0, 0, 0.5)',  // Choose your own color
            planIds: data.map(item => item.planId),
        },
        {
            label: 'CL Import',
            data: data.map(item => item.consumerCosts.breakdown.clUsage / 100),
            backgroundColor: 'rgba(255, 165, 0, 0.5)',  // Choose your own color
            planIds: data.map(item => item.planId),
        },
        {
            label: 'Export',
            data: data.map(item => item.consumerCosts.breakdown.export / 100),
            backgroundColor: 'rgba(0, 128, 0, 0.5)',  // Choose your own color
            planIds: data.map(item => item.planId),
        },
        {
            label: 'Supply',
            data: data.map(item => item.consumerCosts.breakdown.supply / 100),
            backgroundColor: 'rgba(86, 206, 200, 0.5)',  // Choose your own color
            planIds: data.map(item => item.planId),
        },
        {
            label: 'CL Supply',
            data: data.map(item => item.consumerCosts.breakdown.clSupply / 100),
            backgroundColor: 'rgba(86, 156, 155, 0.5)',  // Choose your own color
            planIds: data.map(item => item.planId),
        },
        {
            label: 'Regular Fees',
            data: data.map(item => item.consumerCosts.breakdown.regularFees / 100),
            backgroundColor: 'rgba(153, 102, 255, 0.5)',  // Choose your own color
            planIds: data.map(item => item.planId),
        },
        {
            label: 'GreenPower',
            data: data.map(item => (item.consumerCosts.breakdown.greenPower || 0) / 100),
            backgroundColor: 'rgba(102, 255, 102, 0.5)',  // Choose your own color
            planIds: data.map(item => item.planId),
        },
        {
            label: 'Other Costs',
            data: data.map(item => item.consumerCosts.breakdown.otherCosts / 100),
            backgroundColor: 'rgba(255, 102, 255, 0.5)',  // Choose your own color
            planIds: data.map(item => item.planId),
        },
        
    ];

    let totalCosts = data.map(item => item.netConsumerCost);

    
    datasets.push({
        label: 'Total Cost',
        data: totalCosts,
        planIds: data.map(item => item.planId),
        type: 'line',
        fill: false,
        borderColor: 'rgba(0,0,255,0.5)',  // Choose your own color
        backgroundColor: 'rgba(0,0,255,0.5)',
        tension: 0.1
    });

    targetChart.data.datasets = datasets;
    targetChart.data.labels = labels;
    if (data[0]) targetChart.options.plugins.title.text = `Annual costs calculated from usage period: ${data[0].consumerCosts.periodStart} - ${data[0].consumerCosts.periodEnd}`;
    targetChart.options.onClick =  function(event, activeElements) {
        if(activeElements.length > 0) {
            // Get first clicked element
            const firstPoint = activeElements[0];

            // Get data associated with the clicked element
            const label = this.data.labels[firstPoint.index];  // Get label associated with data
            const value = this.data.datasets[firstPoint.datasetIndex].data[firstPoint.index];  // Get value associated with data
            const planId = this.data.datasets[firstPoint.datasetIndex].planIds[firstPoint.index]; //Get planId
            const emePlanId = planId.split('@')[0]; //Without the @EME or @VEC bit at the end. Suitable for the EME website

            // Construct your URL
            const url = `https://www.energymadeeasy.gov.au/plan?id=${emePlanId}&postcode=${postcode}`;
            
            // Open new tab
            window.open(url, '_blank');
        }
    }

    targetChart.update();
  };

  const handleSubmit = () => {
    setRefreshButton(true);
  }

  return (
      <div className="contentBody">
          <h2>Plan Comparison - annualised costing for each available plan calculated from actual usage data for {selectedConsumer} </h2>
          <h3>Inclusive of GST</h3>
          <h3>Showing {filteredPlanCostData.length} plans</h3>
          <h3>Consumer's current tariff: {consumerTariff}</h3>

          <div id="comparisonChartBox">
              <select className="filterControl" value={selectedBrand} onChange={(e) => setSelectedBrand(e.target.value)}>
                  <option value="">Filter by brand</option>
                  {brands.map(brandObject => (
                      <option key={brandObject.brand} value={brandObject.brand}>{brandObject.brandName}</option>
                  ))}
              </select>

              <select className="filterControl" value={selectedTariff} onChange={(e) => setSelectedTariff(e.target.value)}>
                  <option value="">Filter by tariff</option>
                  {tariffs.map(tariffObject => (
                      <option key={tariffObject.tariffKey} value={tariffObject.tariffKey}>{tariffObject.tariffLabel}</option>
                  ))}
              </select>

              <label className="filterControl">
                  Min Green Power: {greenPower}%
                  <input
                      type="range"
                      min="000"
                      max="100"
                      value={greenPower}
                      onChange={e => setGreenPower(e.target.value)}
                  />
              </label>

              <label className='filterControl'>
                Reference period start date: <input type='date' id='periodStartDate' defaultValue={refPeriod.startDate} onChange={e => setRefPeriod({...refPeriod, startDate: e.target.value})} />
              </label>

              <label className='filterControl'>
                Reference period end date: <input type='date' id='periodEndDate' defaultValue={refPeriod.endDate} onChange={e => setRefPeriod({...refPeriod, endDate: e.target.value})} />
              </label>

              <label className='filterControl'>
                <button onClick={handleSubmit} value="Apply Dates" />
              </label>

              <canvas ref={planComparisonChartRef}></canvas>
          </div>
      </div>
  );
}

export default PlanComparison;
