import React, { useState, useEffect, useContext, createContext } from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import axios from 'axios';
import ConsumerForm from './pages/ConsumerForm';
import PlanComparison from './pages/PlanComparison';
import CurrentPlan from './pages/CurrentPlan';
import BillingHistory from './pages/BillingHistory';
import UsageHistory from './pages/UsageHistory';
import Solar from './pages/Solar';
import SaveMoney from './pages/SaveMoney';
import ReduceEnergy from './pages/ReduceEnergy';
import AuthCallback from './pages/AuthCallback';
import { setupAxiosInterceptors } from './utils/axiosSetup.js';
import ProtectedRoute from './utils/ProtectedRoute';
import { AuthProvider, AuthContext } from './utils/AuthContext';
import { handleLogout } from './utils/AuthLogout';
import Login from './pages/Login.js';
import './App.css'

// Create a context for the selected consumer
const SelectedConsumerContext = createContext();

// Create a provider component
export function SelectedConsumerProvider({ children }) {
  const [selectedConsumer, setSelectedConsumer] = useState('');
  const [selectedConsumerAccount, setSelectedConsumerAccount] = useState('');
  const [selectedServicePoint, setSelectedServicePoint] = useState('');

  return (
    <SelectedConsumerContext.Provider value={{ selectedConsumer, setSelectedConsumer, selectedConsumerAccount, setSelectedConsumerAccount, selectedServicePoint, setSelectedServicePoint }}>
      {children}
    </SelectedConsumerContext.Provider>
  );
}

// Create a custom hook to use the selected consumer state
export function useSelectedConsumer() {
  const context = useContext(SelectedConsumerContext);

  if (!context) {
    throw new Error('useSelectedConsumer must be used within a SelectedConsumerProvider');
  }

  return context;
}

function startLoadingSpinner(elementId) {
  console.debug(`Starting loading spinner for DIV#${elementId}`)
  // Check if the spinner already exists, and if so, do nothing
  if (document.getElementById('spinner-' + elementId)) return;

  var spinner = document.createElement("div");
  spinner.className = "spinner";
  spinner.innerHTML = '<div class="rect1"></div><div class="rect2"></div><div class="rect3"></div><div class="rect4"></div><div class="rect5"></div>';
  spinner.id = 'spinner-' + elementId;  // Assign a unique id to the spinner

  // Append the spinner to the specified div
  if (document.getElementById(elementId)) document.getElementById(elementId).appendChild(spinner);
}

function stopLoadingSpinner(elementId) {
  console.debug(`Stopping loading spinner for DIV#${elementId}`)
  var spinner = document.getElementById('spinner-' + elementId);

  // If the spinner exists, remove it
  if (spinner) {
    spinner.parentNode.removeChild(spinner);
  }
}

function Navigation({ consumers }) {
  const { selectedConsumer, setSelectedConsumer, selectedConsumerAccount, setSelectedConsumerAccount, selectedServicePoint, setSelectedServicePoint } = useSelectedConsumer();
  const [accounts, setAccounts] = useState([]);
  const [servicePoints, setServicePoints] = useState([]);

  useEffect(() => {
    if (selectedConsumer && selectedConsumer != '') {
      startLoadingSpinner('navBar');
      axios.get(`${process.env.REACT_APP_API_URL}consumers/${selectedConsumer}/accounts`)
        .then(response => {
          setAccounts(response.data);
        })
        .catch(error => {
          console.error('Error fetching consumer IDs:', error);
        }).finally(() => {
          stopLoadingSpinner('navBar');
        });
    }
  }, [selectedConsumer]);

  useEffect(() => {
    if (selectedConsumerAccount && selectedConsumerAccount != '') {
      startLoadingSpinner('navBar');
      axios.get(`${process.env.REACT_APP_API_URL}consumers/${selectedConsumer}/accounts/${selectedConsumerAccount}/service-points`)
        .then(response => {
          setServicePoints(response.data);
        })
        .catch(error => {
          console.error('Error fetching consumer IDs:', error);
        }).finally(() => {
          stopLoadingSpinner('navBar');
        });
    }
  }, [selectedConsumerAccount]);

  useEffect(() => {
    if (accounts.length == 1) {
      console.log(`Found only one account for ${selectedConsumer}, so setting it to be selected`)
      setSelectedConsumerAccount(accounts[0].accountId);
    }
  }, [accounts]);

  useEffect(() => {
    if (servicePoints.length == 1) {
      console.log(`Found only one service point for ${selectedConsumer} and account ${selectedConsumerAccount}, so setting the selected service point to be ${servicePoints[0].servicePointId}`)
      setSelectedServicePoint(servicePoints[0].servicePointId);
    }
  }, [servicePoints]);

  const handleConsumerChange = (event) => {
    setSelectedConsumer(event.target.value);
    setSelectedConsumerAccount('');
    setSelectedServicePoint('');
  };

  const handleConsumerAccountChange = (event) => {
    setSelectedConsumerAccount(event.target.value);
    setSelectedServicePoint('');
  };

  const handleServicePointChange = (event) => {
    setSelectedServicePoint(event.target.value);
  };

  return (
    <Router>
      <div id='navBar'>

        <nav>
          <select value={selectedConsumer} onChange={handleConsumerChange}>
            <option value="">Select a consumer</option>
            {consumers.map(consumer => (
              <option key={consumer.id} value={consumer.id}>{consumer.name}</option>
            ))}
          </select>

          <select value={selectedConsumerAccount} onChange={handleConsumerAccountChange}>
            <option value="">Select a consumer account</option>
            {accounts.map(account => (
              <option key={account.accountId} value={account.accountId}>[{account.brandName}] {account.displayName} - {account.accountNumber} ({account.openStatus})</option>
            ))}
          </select>

          <select value={selectedServicePoint} onChange={handleServicePointChange}>
            <option value="">Select a service point</option>
            {servicePoints.map(servicePoint => (
              <option key={servicePoint.servicePointId} value={servicePoint.servicePointId}>NMI: {servicePoint.nationalMeteringId} - {servicePoint.postcode} ({servicePoint.servicePointStatus})</option>
            ))}
          </select>

          <ul>
            <li>
              <Link to="/consumer-admin">Consumer admin</Link>
            </li>
            <li>
              <Link to="/current-plan">Current Plan</Link>
            </li>
            <li>
              <Link to="/plan-comparison">Plan comparison</Link>
            </li>
            <li>
              <Link to="/billing-history">Billing history</Link>
            </li>
            <li>
              <Link to="/usage-history">Usage history</Link>
            </li>
            <li>
              <Link to="/solar">Solar & battery simulator</Link>
            </li>
            <li>
              <Link to="/save-money">Save money (be leaner)</Link>
            </li>
            <li>
              <Link to="/reduce-energy">Reduce energy (be greener)</Link>
            </li>
          </ul>
        </nav>
      </div>

      <div id="content">
        <Routes>
          <Route path="/" element={<ProtectedRoute component={CurrentPlan} />} />
          <Route path="/login" element={<Login />} />
          <Route path="/consumer-admin" element={<ProtectedRoute component={ConsumerForm} />} />
          <Route path="/current-plan" element={<ProtectedRoute component={CurrentPlan} />} />
          <Route path="/plan-comparison" element={<ProtectedRoute component={PlanComparison} />} />
          <Route path="/billing-history" element={<ProtectedRoute component={BillingHistory} />} />
          <Route path="/usage-history" element={<ProtectedRoute component={UsageHistory} />} />
          <Route path="/solar" element={<ProtectedRoute component={Solar} />} />
          <Route path="/save-money" element={<ProtectedRoute component={SaveMoney} />} />
          <Route path="/reduce-energy" element={<ReduceEnergy />} />
          <Route path="/authCallback" element={<AuthCallback />} />
        </Routes>
      </div>

    </Router>

  );
}

function UserGreeting() {
  const { user } = useContext(AuthContext);

  if (user) {
    return (
      <h5>
        Logged in as: {user.attributes.email} <button onClick={handleLogout}>Log Out</button>
      </h5>
    );
  } else {
    return (
      <h5>
        Not logged in.
      </h5>
    )
  }

}

function EnvironmentBanner() {
  const [content, setContent] = useState(undefined);
  const env = process.env.REACT_APP_ENVIRONMENT?.trim()?.toUpperCase();
  let msg = 'Non-production environment!';

  useEffect(() => {
    if (env == 'PRODUCTION' || env == 'PROD') {
      setContent(undefined); //No banner to display
      return;
    } else {
      if (!env) {
        msg += ` Missing environment variable 'REACT_APP_ENVIRONMENT'.`
      } else {
        msg = `Non-production environment: ${env}`
      }
      setContent(<p className='environmentBanner'>{msg}</p>);
    }
  }, [])

  return content;

}


function App() {
  const [consumers, setConsumers] = useState([]);

  var loadCounter = 0;

  useEffect(() => {
    loadCounter++;
    if (loadCounter == 1) { //Ensure only on first load
      setupAxiosInterceptors();

      startLoadingSpinner('navBar');
      axios.get(process.env.REACT_APP_API_URL + 'consumers')
        .then(response => {
          setConsumers(response.data);
        })
        .catch(error => {
          console.error('Error fetching consumer IDs:', error);
        }).finally(() => {
          stopLoadingSpinner('navBar');
        });
    }
  }, []);  // Empty dependency array means this effect runs once on mount


  return (
    <AuthProvider>
      <EnvironmentBanner />
      <SelectedConsumerProvider>
        <UserGreeting />
        <Navigation
          consumers={consumers}
        />
      </SelectedConsumerProvider>
    </AuthProvider>
  );
}

export default App;
